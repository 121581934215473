import "./index.scss";

interface RectangleBtnProps {
  children: React.ReactNode;
  onClick: () => void;
  active?: boolean;
  backColor?: any;
  textColor?: string;
  fontSize?: string;
  radius?: string;
  border?: HTMLButtonElement["style"]["border"];
}

export default function RectangleBtn({
  children,
  onClick,
  active = true,
  backColor = "#000",
  textColor = "#fff",
  fontSize = "0.875rem",
  radius = ".5rem",
  border = "none",
}: RectangleBtnProps) {
  return (
    <button
      onClick={onClick}
      disabled={!active}
      className="rectangle-btn-container"
      style={{
        cursor: active ? "pointer" : "not-allowed",
        backgroundColor: backColor,
        color: textColor,
        fontSize,
        borderRadius: radius,
        border,
      }}>
      {children}
    </button>
  );
}
