class CustomColor {
  hex = "#000000";

  constructor(hex = "#000000") {
    this.hex = hex;
  }

  withOpacity(opacity = 1) {
    const [r, g, b] = this.hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${opacity})`;
  }

  valueOf() {
    return this.hex;
  }

  toString() {
    return this.hex;
  }

  __emotion_styles() {
    return this.hex;
  }
}

function Color(hex = "#000000"): CustomColor {
  const color = new CustomColor(hex);

  return color;
}

const COLORS = {
  primary: Color("#377bff"),

  black: Color("#020202"),
  dark: Color("#1e222a"),
  white: Color("#fff"),
  background: Color("#060e1d"),

  skeleton: Color("#2A313D"),
  blur: Color("#202838"),
  tooltip: Color("#2a313d"),
  smallButton: Color("#1a2233"),
  error: Color("#ff2d07"),

  green: Color("#3fb68b"),
  red: Color("#ff0059"),
  grayblue: Color("#7c8aa6"),
};

export default COLORS;
