import {BrowserRouter, Route, Routes} from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import ReservationPage from "../pages/ReservationPage";
import { ROUTES } from "./ROUTES";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.RESERVATION} element={<ReservationPage/>} />
        <Route path={ROUTES.MAIN} element={<LandingPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router;