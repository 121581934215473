import "../styles/reservationPage.scss";
import { useState } from "react";
import BasicInput from "../components/inputs/BasicInput";
import { SizedBox } from "../components/utils/SizedBox";
import Header from "../components/Header";
import { Gap } from "../components/utils/Gap";
import COLORS from "../styles/globalColors";
import RectangleBtn from "../components/buttons/RectangleBtn";
import { Row } from "../components/utils/Row";
import { Column } from "../components/utils/Column";
import { LayerAlign } from "../components/utils/scripts/LayerAlign";

export default function ReservationPage() {
  const [inputValue, setInputValue] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<boolean>(false);

  // 이메일 형식 검증 함수
  const validateEmail = (email: string) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  // 폼 제출 함수
  const handleSubmit = async () => {
    if (!inputValue || inputValue === "") {
      setErrorMessage("Please enter your email address.");
      setSuccessMessage(false);
      return;
    }

    if (!validateEmail(inputValue)) {
      setErrorMessage(
        "Invalid email format. Please enter a valid email address."
      );
      setSuccessMessage(false);
      return;
    }

    try {
      const response = await fetch(
        "https://hooks.zapier.com/hooks/catch/20105872/2hawegm/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: inputValue }),
        }
      );

      console.log(response);

      if (response.ok && response.status === 200) {
        const json = await response.json();

        if (json.status === "duplicate") {
          setErrorMessage(
            "This email is already registered. Please use a different email."
          );
          setSuccessMessage(false);
        } else {
          setErrorMessage("");
          setSuccessMessage(true);
        }

        setInputValue(""); // 입력 필드 초기화
      } else {
        setErrorMessage("An error occurred. Please refresh and try again.");
        setSuccessMessage(false);
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please refresh and try again.");
      setSuccessMessage(false);
    }
  };

  return (
    <div className="reservation-page-layout">
      <Header />

      <Gap h={80} />

      <Gap h={"15rem"} />
      <Column>
        <Row>
          <SizedBox
            w={"450px"}
            h={60}
          >
            <BasicInput
              value={inputValue}
              onChange={setInputValue}
              backColor={COLORS.white.hex}
              border={`2px solid ${COLORS.grayblue}`}
              placeholder="Enter your email"
              round="0.5rem"
              type="email"
            />
          </SizedBox>

          <Gap w={10} />
          <SizedBox
            w={"8rem"}
            h={60}
          >
            <RectangleBtn
              onClick={handleSubmit}
              backColor={COLORS.primary}
            >
              Submit
            </RectangleBtn>
          </SizedBox>
        </Row>

        <Gap h={10} />
        <Row
          fullWidth
          main={LayerAlign.end}
        >
          {/* 에러 메시지 표시 */}
          {errorMessage && (
            <p style={{ color: COLORS.red.hex }}>{errorMessage}</p>
          )}

          {/* 성공 메시지 표시 */}
          {successMessage && (
            <p style={{ color: COLORS.green.hex }}>
              Thank you! Your registration is complete.
            </p>
          )}

          <Gap w={12} />
          <Gap w="8rem" />
        </Row>
      </Column>
    </div>
  );
}
