import { useRef, useEffect, useCallback } from "react";

const useScrollFadeIn = (direction = "up", duration = 1, delay = 0) => {
  const element = useRef<any>();

  const handleDirection = (name: string) => {
    switch (name) {
      case "up":
        return "translate3d(0, 10%, 0)";
      case "down":
        return "translate3d(0, -10%, 0)";
      case "left":
        return "translate3d(10%, 0, 0)";
      case "right":
        return "translate3d(-10%, 0, 0)";
      default:
        return;
    }
  };

  const handleScroll: IntersectionObserverCallback = useCallback(
    ([entry]: IntersectionObserverEntry[]) => {
      const { current } = element;
      if (entry.isIntersecting) {
        current.style.transitionProperty = "all";
        current.style.transitionDuration = `${duration}s`;
        current.style.transitionTimingFunction = "cubic-bezier(0.25, 0.25, 0.75, 0.75)";
        current.style.transitionDelay = `${delay}s`;
        current.style.opacity = '1';
        current.style.transform = "translate3d(0, 0, 0)";
      }
    },
    [delay, duration]
  );

  useEffect(() => {
    let observer: IntersectionObserver;
    const { current } = element;

    if (current) {
      observer = new IntersectionObserver(handleScroll, { threshold: 0.3 });
      observer.observe(current);
    }

    return () => observer && observer.disconnect();
  }, [handleScroll]);

  return {
    ref: element,
    style: {
      opacity: 0,
      transform: handleDirection(direction),
    },
  };
};

export default useScrollFadeIn;
