import React from "react";

import "./index.scss";
import { LayerAlign } from "../scripts/LayerAlign";

/**
 * main => 주축 정렬
 * cross => 교차축 정렬
 */

interface ColumnProps {
  main?: string;
  cross?: string;
  fullWidth?: boolean;
  fullHeight?: boolean;
  flex?: number;
  wrap?: boolean;
  gap?: number;
  children?: React.ReactNode;
}

export const Column: React.FC<ColumnProps> = ({
  main = LayerAlign.center,
  cross = LayerAlign.center,
  fullWidth = false,
  fullHeight = false,
  flex = 0,
  wrap = false,
  gap = 0,
  children,
}) => {
  return (
    <div
      className="column"
      style={{
        flex,
        justifyContent: main,
        alignItems: cross,
        width: fullWidth ? "100%" : "max-content",
        height: fullHeight ? "100%" : "max-content",
        flexWrap: wrap ? "wrap" : "nowrap",
        gap,
      }}>
      {children}
    </div>
  );
};
