import "./index.scss";
import Logo from "../../assets/svgs/dvx-logo-beta.svg";
import { useEffect, useRef, useState } from "react";
import Discord from "../../assets/svgs/icon-discord.svg";
import Twitter from "../../assets/svgs/icon-twitter.svg";
import Telegram from "../../assets/svgs/icon-telegram.svg";
import Zealy from "../../assets/pngs/img-zealy.png";
import { ROUTES } from "../../routes/ROUTES";

const Header = () => {
  const disconnectRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleDisconnectVisibility = () => {
    if (disconnectRef.current) {
      disconnectRef.current.style.visibility =
        disconnectRef.current.style.visibility === "hidden"
          ? "visible"
          : "hidden";
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    toggleDisconnectVisibility();
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    toggleDisconnectVisibility();
  };

  return (
    <div className={"header"}>
      <img
        className={"logo"}
        src={Logo}
        alt={""}
        onClick={() => {
          window.location.href = ROUTES.MAIN;
        }}
      />
      {isMobile ? (
        <div className={"header-contents"}>
          <div
            className={"menu"}
            onClick={() =>
              window.open(
                "https://dvx-trading.gitbook.io/dvx-docs/getting-started/overview",
                "_blank"
              )
            }
          >
            Docs
          </div>
          <div
            className={"header-button"}
            onClick={() => window.open("https://app.dvx.trading", "_blank")}
          >
            Launch App
          </div>
        </div>
      ) : (
        <div className={"header-contents"}>
          <div className={"community-container"}>
            <div
              className={"menu community"}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Community
            </div>
            {isHovered && (
              <div
                className={"community-box"}
                ref={disconnectRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div
                  className={"community"}
                  onClick={() =>
                    window.open("https://discord.gg/dvxtrading", "_blank")
                  }
                >
                  <img
                    className={"sns"}
                    src={Discord}
                    alt={""}
                  />
                  <div className={"label"}> Discord</div>
                </div>
                <div
                  className={"community"}
                  onClick={() =>
                    window.open("https://twitter.com/dvx_official", "_blank")
                  }
                >
                  <img
                    className={"sns"}
                    src={Twitter}
                    alt={""}
                  />
                  <div className={"label"}> Twitter</div>
                </div>
                <div
                  className={"community"}
                  onClick={() =>
                    window.open("https://t.me/dvxtrading", "_blank")
                  }
                >
                  <img
                    className={"sns"}
                    src={Telegram}
                    alt={""}
                  />
                  <div className={"label"}> Telegram</div>
                </div>
                <div
                  className={"community"}
                  onClick={() =>
                    window.open("https://zealy.io/cw/dvx/questboard", "_blank")
                  }
                >
                  <img
                    className={"sns"}
                    src={Zealy}
                    alt={""}
                  />
                  <div className={"label"}> Zealy</div>
                </div>
              </div>
            )}
          </div>
          <div
            className="menu"
            onClick={() =>
              window.open(
                "https://dvx-trading.gitbook.io/dvx-docs/getting-started/overview",
                "_blank"
              )
            }
          >
            Docs
          </div>
          {/* <div className="margin-block" /> */}
          <div
            className={"header-button"}
            onClick={() => {
              // window.open("https://forms.gle/cdektHiK9X5mnoSY7", "_blank")
              window.location.href = ROUTES.RESERVATION;
            }}
          >
            Sign Up for Updates
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
