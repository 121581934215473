import React from "react";
import "./index.scss";

interface GapProps {
  w?: number | string;
  h?: number | string;
  children?: React.ReactNode;
}

export const Gap: React.FC<GapProps> = ({ w = 0, h = 0, children }) => {
  const marginRight = typeof w === "number" ? `${w}px` : w;
  const marginTop = typeof h === "number" ? `${h}px` : h;

  return (
    <div className="gap" style={{ marginRight, marginTop }}>
      {children}
    </div>
  );
};
