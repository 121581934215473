import React, { ReactElement } from "react";
import "./index.scss";

interface SizedBoxProps {
  w?: HTMLElement["style"]["width"] | number;
  h?: HTMLElement["style"]["height"] | number;
  flex?: number;
  inherit?: boolean;
  children?: ReactElement;
}

export const SizedBox: React.FC<SizedBoxProps> = ({
  w,
  h,
  flex = undefined,
  inherit = false,
  children,
}) => {
  if (!inherit) {
    w = w ? w : "max-contents";
    h = h ? h : "max-contents";
  }

  w = typeof w === "number" ? `${w}px` : w;
  h = typeof h === "number" ? `${h}px` : h;

  if (inherit && children) {
    return React.cloneElement(children, {
      style: {
        ...children.props.style, // 기존 스타일 유지
        width: w,
        height: h,
      },
    });
  }

  return (
    <div className="sized-box" style={{ width: w, height: h, flex }}>
      {children}
    </div>
  );
};
