import COLORS from "../../../styles/globalColors";
import "./index.scss";

interface BasicInputProps {
  disabled?: boolean;
  type?: string;
  value: string;
  onChange: (value: string) => void;
  prefix?: string;
  placeholder?: string;
  round?: HTMLElement["style"]["borderRadius"];
  backColor?: string;
  border?: HTMLElement["style"]["border"];
}

export default function BasicInput({
  disabled = false,
  type = "text",
  value,
  onChange,
  placeholder = "",
  round = "1rem",
  backColor = COLORS.black.withOpacity(0.3),
  border = "none",
}: BasicInputProps) {
  const handleChange = (value: string) => {
    onChange(value);
  };

  return (
    <div
      className="basic-input-board"
      style={{ backgroundColor: backColor, borderRadius: round, border }}>
      <input
        disabled={disabled}
        className={"basic-input"}
        type={type}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={placeholder}
        style={{
          width: "100%",
          padding: "1.2rem 1.6rem",
          borderRadius: round,
          border: "none",
        }}
      />
    </div>
  );
}
